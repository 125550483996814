import React from "react"
import { graphql } from "gatsby"
import Resource from "./resource"

export default Resource

export const query = graphql`
  fragment WhitepaperItem on DatoCmsWhitepaper {
    title
    slug
    description
    image {
      file {
        publicURL
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }

  query Whitepaper($id: String, $language: String) {
    page: datoCmsWhitepaper(id: { eq: $id }, locale: { eq: $language }) {
      title
      slug
      description
      parentPage {
        title
        slug
      }
      sections {
        __typename
        ... on DatoCmsFormSection {
          ...DatoCmsFormSection
        }
      }
    }
  }
`
